import React, { Component } from "react"

// Styles
import Styles from "./PanelSiteReferent.module.styl"
import CommonStyles from "./PanelCommons.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import LinkPrimary from "~components/Links/LinkPrimary"

class PanelSiteReferent extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                className={`${CommonStyles.PanelResultat} ${Styles.PanelSiteReferent}`}
            >
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.PanelSiteReferent__inner}>
                            <div className={Styles.PanelSiteReferent__left}>
                                <p
                                    className={`${Styles.PanelSiteReferent__chapo}`}
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.content,
                                    }}
                                ></p>
                                <LinkPrimary
                                    extraClassNames={`${Styles.PanelSiteReferent__link}`}
                                    to={this.props.cta.url}
                                    title={this.props.cta.label}
                                />
                            </div>
                            <div className={Styles.PanelSiteReferent__right}>
                                <img
                                    src={this.props.imagePath}
                                    className={Styles.PanelSiteReferent__img}
                                />
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelSiteReferent
