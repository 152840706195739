import React, { Component } from "react"
import { Link } from "gatsby"

// Helpers
import SptkUtils from "~helpers/sptk"

// Libs
import anime from "animejs/lib/anime.es.js"

// Styles
import Styles from "./MailWall.module.styl"
import { Row, Cell } from "~layout/Layout"
import FormMailwall from "~components/Forms/FormMailwall.js"

// Components
import Icon from "~icons/Icon"

class MailWall extends Component {
    constructor(props) {
        super(props)

        this.helpers = new SptkUtils()

        // ES6 Rebind
        this.onSuccess = this.onSuccess.bind(this)

        // Refs
        this.mailWall = React.createRef()
    }

    state = {
        isSuccess: false,
    }

    onSuccess(e) {
        this.setState({
            isSuccess: true,
        })
    }

    onClickBtnClose(e) {
        e.preventDefault()

        anime({
            targets: this.mailWall.current,
            translateY: "6.4rem",
            opacity: 0,
            duration: 500,
            easing: "easeOutQuad",
            complete: (anim) => {
                this.props.onSuccess()
            },
        })
    }

    render() {
        return (
            <div className={`${Styles.MailWall}`} ref={this.mailWall}>
                <div className={`${Styles.MailWall__inner}`}>
                    <Row>
                        <Cell start="0" end="12">
                            {!this.state.isSuccess && (
                                <div className={`${Styles.MailWall__content}`}>
                                    <div className={`${Styles.MailWall__left}`}>
                                        <h3 className={`heading-3`}>
                                            Pour lire l'intégralité de l'étude,
                                            laissez-nous votre e-mail
                                        </h3>
                                        <p
                                            className={
                                                Styles.MailWall__textLeft
                                            }
                                        >
                                            Promis, pas de spam et on ne donnera
                                            votre email à personne. En vous
                                            inscrivant, vous aurez accès aux
                                            résultats de votre test et aux
                                            autres profils de sites ainsi qu’à
                                            notre étude complète.
                                        </p>
                                    </div>
                                    <div
                                        className={`${Styles.MailWall__right}`}
                                    >
                                        <p
                                            className={`very-small ${Styles.MailWall__textRight}`}
                                        >
                                            Ces informations sont
                                            confidentielles et conservées en
                                            toute sécurité. En savoir plus sur
                                            la{" "}
                                            <a
                                                target="_blank"
                                                rel="noopener"
                                                href={`/mentions-legales`}
                                                className={`${Styles.MailWall__textRight__link}`}
                                            >
                                                gestion des données
                                                personnelles.
                                            </a>
                                        </p>
                                        <FormMailwall
                                            className={Styles.MailWall__input}
                                            isBtn={false}
                                            isLargeInput={true}
                                            isLargeBtn={true}
                                            onSuccess={this.onSuccess}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.state.isSuccess && (
                                <div className={`${Styles.MailWall__content}`}>
                                    <div
                                        className={`${Styles.MailWall__success}`}
                                    >
                                        <button
                                            className={`${Styles.MailWall__success__close}`}
                                            onClick={(e) => {
                                                e.persist()
                                                this.onClickBtnClose(e)
                                            }}
                                        >
                                            <Icon
                                                name="burger-close"
                                                width={
                                                    this.helpers.utils
                                                        .isMobileTablet
                                                        ? 24
                                                        : 48
                                                }
                                                height={
                                                    this.helpers.utils
                                                        .isMobileTablet
                                                        ? 24
                                                        : 48
                                                }
                                                fill="#000"
                                            />
                                        </button>
                                        <div
                                            className={`${Styles.MailWall__success__left}`}
                                        >
                                            <Icon
                                                name="success"
                                                width={144}
                                                height={144}
                                                extraClasses={`${Styles.MailWall__success__icon}`}
                                            />
                                        </div>
                                        <div
                                            className={`${Styles.MailWall__success__right}`}
                                        >
                                            <p className={`heading-3`}>
                                                Merci, vous avez maintenant
                                                accès à l’ensemble des résultats
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Row>
                </div>
            </div>
        )
    }
}

export default MailWall
