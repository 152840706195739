import React, { Component } from "react"

// Styles
import CommonStyles from "~components/Panels/Home/PanelCommons.module.styl"
import Styles from "./PanelProfil.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import SVGProfil from "~components/SVGs/SVGProfil"

class PanelProfil extends Component {
    constructor(props) {
        super(props)
    }

    reorderValues(objectToReorder) {
        return {
            business: objectToReorder.business,
            vision: objectToReorder.vision,
            service: objectToReorder.service,
            reference: objectToReorder.reference,
        }
    }

    render() {
        return (
            <div className={`${CommonStyles.PanelWhite} ${Styles.PanelProfil}`}>
                <Row>
                    <Cell start="0" end="10">
                        <div className={Styles.PanelProfil__inner}>
                            <div className={Styles.PanelProfil__left}>
                                <h2 className="heading-4">Votre profil</h2>
                                <div
                                    className={`${Styles.PanelProfil__left__families}`}
                                >
                                    <p
                                        className={`${Styles.PanelProfil__left__families__item}`}
                                    >
                                        Majeure :{" "}
                                        <span className={`graph-legend`}>
                                            {this.props.families.primary}
                                        </span>
                                    </p>
                                    <p
                                        className={`${Styles.PanelProfil__left__families__item}`}
                                    >
                                        Mineure :{" "}
                                        <span className={`graph-legend`}>
                                            {this.props.families.secondary}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className={Styles.PanelProfil__right}>
                                <div
                                    className={
                                        Styles.PanelProfil__right__wrapper
                                    }
                                >
                                    <div
                                        className={
                                            Styles.PanelProfil__graph__labels
                                        }
                                    >
                                        <div
                                            className={`${Styles.PanelProfil__graph__labels__top}`}
                                        >
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__center}`}
                                            >
                                                Vision
                                            </p>
                                        </div>
                                        <div
                                            className={`${Styles.PanelProfil__graph__labels__center}`}
                                        >
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__left}`}
                                            >
                                                Business
                                            </p>
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__right}`}
                                            >
                                                Service
                                            </p>
                                        </div>
                                        <div
                                            className={`${Styles.PanelProfil__graph__labels__bottom}`}
                                        >
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__center}`}
                                            >
                                                Référence
                                            </p>
                                        </div>
                                    </div>
                                    <SVGProfil
                                        resultValues={
                                            this.props.resultValues.isAllDone
                                                ? this.reorderValues(
                                                      this.props.resultValues
                                                          .scores
                                                  )
                                                : false
                                        }
                                        siteValues={this.props.siteValues}
                                    />
                                </div>
                            </div>
                            <div className={Styles.PanelProfil__legend}>
                                <ul
                                    className={Styles.PanelProfil__legend__list}
                                >
                                    {this.props.resultValues.isAllDone && (
                                        <li
                                            className={`${Styles.PanelProfil__legend__list__item} ${Styles.PanelProfil__legend__list__item__project} tag u-f-upper`}
                                        >
                                            Votre projet
                                        </li>
                                    )}
                                    <li
                                        className={`${Styles.PanelProfil__legend__list__item} tag u-f-upper`}
                                    >
                                        Site {this.props.siteName} type
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelProfil
