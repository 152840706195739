import React, { Component } from "react"
import { Link } from "gatsby"

// Layout
import { Row, Cell, View, Panel, Footer } from "~layout/Layout"

// Components
import PanelTitle from "~components/Panels/Resultats/PanelTitle"
import PanelIntro from "~components/Panels/Resultats/PanelIntro"
import PanelProfil from "~components/Panels/Resultats/PanelProfil"
import TopBarResultat from "~components/TopBars/TopBarResultat"
import PanelReussir from "~components/Panels/Resultats/PanelReussir"
import PanelRisques from "~components/Panels/Resultats/PanelRisques"
import PanelPriorites from "~components/Panels/Resultats/PanelPriorites"
import PanelPlusLoin from "~components/Panels/Resultats/PanelPlusLoin"
import PanelAboutir from "~components/Panels/Resultats/PanelAboutir"
import PanelSiteReferent from "~components/Panels/Resultats/PanelSiteReferent"
import Supfooter from "~components/Supfooters/Supfooter"
import MailWall from "~components/MailWall/MailWall"
import CanvasBackground from "~components/Canvases/CanvasBackground"
import Metas from "~misc/Metas"

// Store
import UserStore from "~stores/UserStore"
import QuestionStore from "~stores/QuestionStore"

class SitePage extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.onSuccess = this.onSuccess.bind(this)
        console.log(this.props)
    }

    state = {
        isMailWalled: UserStore.getCookieEmailState() !== true,
    }

    onSuccess() {
        this.setState({
            isMailWalled: false,
        })
    }

    componentDidMount() {}

    render() {
        return (
            <View
                name="resultat"
                theme={this.props.pageContext.theme}
                extraClassNames={`${
                    this.state.isMailWalled && typeof window !== "undefined"
                        ? "is-mailwalled"
                        : ""
                }`}
            >
                <Metas
                    title={`Le site ${this.props.pageContext.name}`}
                    description={this.props.pageContext.chapo}
                    image={""}
                    shouldIndex={true}
                />

                <CanvasBackground />
                <PanelTitle
                    title={this.props.pageContext.name}
                    id={this.props.pageContext.id}
                />

                <PanelIntro
                    chapo={this.props.pageContext.chapo}
                    description={this.props.pageContext.description}
                />

                <PanelProfil
                    siteName={this.props.pageContext.name}
                    siteValues={this.props.pageContext.chartsValues}
                    resultValues={QuestionStore.getStatus()}
                    families={this.props.pageContext.families}
                />

                <div className={"view__topbar"}>
                    <Row>
                        <Cell start="0" end="12">
                            <TopBarResultat
                                label="Accès direct"
                                data={[
                                    {
                                        name: "Réussir ce projet",
                                        slug: "reussir",
                                    },
                                    {
                                        name: "Risques et arbitrages",
                                        slug: "risques",
                                    },
                                    {
                                        name: "Les priorités",
                                        slug: "priorites",
                                    },
                                    {
                                        name: "Aller plus loin",
                                        slug: "plus_loin",
                                    },
                                ]}
                            />
                        </Cell>
                    </Row>
                </div>

                <PanelReussir
                    carouselItems={
                        this.props.pageContext.panelReussir.carouselItems
                    }
                    detailsItems={
                        this.props.pageContext.panelReussir.detailsItems
                    }
                    isMailWalled={this.state.isMailWalled}
                />

                {!this.state.isMailWalled && typeof window !== "undefined" && (
                    <div className={`view__mailwalled`}>
                        <PanelRisques
                            items={
                                this.props.pageContext.panelRisquesArbitrages
                                    .items
                            }
                        />

                        <PanelPriorites
                            title={this.props.pageContext.name}
                            items={
                                this.props.pageContext.panelPriorites
                                    .expandItems
                            }
                        />

                        <PanelPlusLoin
                            profilID={this.props.pageContext.id}
                            title={this.props.pageContext.panelPlusLoin.title}
                            chapo={this.props.pageContext.panelPlusLoin.chapo}
                            cta={this.props.pageContext.panelPlusLoin.cta}
                            family={
                                this.props.pageContext.panelPlusLoin
                                    .carouselFamily
                            }
                        />

                        <PanelAboutir
                            {...this.props.pageContext.panelAboutir}
                        />

                        {/* <PanelSiteReferent
                            {...this.props.pageContext.panelSiteReferent}
                        />*/}

                        <div className={`view__bottom`}>
                            <Supfooter />
                            <Footer />
                        </div>
                    </div>
                )}

                {this.state.isMailWalled && typeof window !== "undefined" && (
                    <MailWall onSuccess={this.onSuccess} />
                )}
            </View>
        )
    }
}

export default SitePage
