import { checkPropTypes } from "prop-types"
import React, { Component } from "react"
import Icon from "~icons/Icon"
import PropTypes from "prop-types"

// Styles
import Styles from "./FormMailwall.module.styl"

// Stores
import UserStore from "~stores/UserStore"
import QuestionStore from "~stores/QuestionStore"

class FormMailwall extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleResponseSuccess = this.handleResponseSuccess.bind(this)
        this.handleResponseError = this.handleResponseError.bind(this)

        // Refs
        this.form = React.createRef()
    }

    state = {
        value: "",
        textResponse: "",
        isAppend: false,
        isError: false,
        isValid: false,
    }

    async handleSubmit(event) {
        event.preventDefault()

        const formData = new FormData(this.form.current)
        let objectToSend = Object.fromEntries(formData)

        if (QuestionStore.isAllDone) {
            objectToSend = {
                ...objectToSend,
                ...QuestionStore.getDoneQuestionsData(),
            }
        }

        UserStore.submitFormNewsletter(
            objectToSend,
            this.handleResponseSuccess,
            this.handleResponseError
        )
    }

    handleResponseSuccess(data, res) {
        if (res.ok === false) {
            this.setState({
                isError: true,
                textResponse: "L'email n'est pas valide",
            })

            setTimeout(() => {
                this.resetFormStates()
            }, 3000)
        } else {
            if (
                data.text === "E-mail déjà existant !" ||
                data.text === "Champs ajoutés !"
            ) {
                this.setState({
                    isError: true,
                    isValid: true,
                    textResponse: "E-mail déjà envoyé !",
                })
            } else if (data.text === "E-mail ajouté !") {
                this.setState({
                    isError: false,
                    isAppend: true,
                    isValid: true,
                    textResponse: "E-mail bien envoyé !",
                })
            }

            UserStore.setCookieForEmail(data.token)
        }

        if (this.state.isValid && this.props.onSuccess) {
            this.props.onSuccess()
        }
    }

    resetFormStates() {
        this.setState({
            textResponse: "",
            isAppend: false,
            isError: false,
            isValid: false,
        })
    }

    handleResponseError() {
        this.setState({
            textResponse: "Erreur inconnue",
        })
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
        })
    }

    render() {
        return (
            <form
                className={`${Styles.Form} form`}
                ref={this.form}
                onSubmit={this.handleSubmit}
            >
                <div className={`${Styles.Form__row}`}>
                    <div
                        className={`${Styles.Form__item} ${Styles.Form__item__full}`}
                    >
                        <input
                            name="email_address"
                            type="email"
                            value={this.state.value}
                            placeholder="VOTRE ADRESSE E-MAIL*"
                            className={`form__text ${
                                Styles.Form__input
                            } teasing-1 ${
                                this.props.isLargeInput
                                    ? `${Styles.Form__isLarge}`
                                    : ""
                            }`}
                            required
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className={`${Styles.Form__row}`}>
                    <div className={`${Styles.Form__item}`}>
                        <input
                            name="nom"
                            type="text"
                            placeholder="NOM*"
                            required
                            className={`form__text ${
                                Styles.Form__input
                            } teasing-1 ${
                                this.props.isLargeInput
                                    ? `${Styles.Form__isLarge}`
                                    : ""
                            }`}
                        />
                    </div>
                    <div className={`${Styles.Form__item}`}>
                        <input
                            name="prenom"
                            type="text"
                            placeholder="PRÉNOM*"
                            required
                            className={`form__text ${
                                Styles.Form__input
                            } teasing-1 ${
                                this.props.isLargeInput
                                    ? `${Styles.Form__isLarge}`
                                    : ""
                            }`}
                        />
                    </div>
                </div>

                <div className={`${Styles.Form__row}`}>
                    <div
                        className={`${Styles.Form__item} ${Styles.Form__item__full}`}
                    >
                        <input
                            name="organisation"
                            type="text"
                            placeholder="ORGANISATION*"
                            required
                            className={`form__text ${
                                Styles.Form__input
                            } teasing-1 ${
                                this.props.isLargeInput
                                    ? `${Styles.Form__isLarge}`
                                    : ""
                            }`}
                        />
                    </div>
                </div>

                <div className={`${Styles.Form__row}`}>
                    <div
                        className={`${Styles.Form__item} ${Styles.Form__item__full}`}
                    >
                        <label
                            className={`${Styles.Form__checkbox}`}
                            htmlFor="add_to_nl"
                        >
                            <input
                                id="add_to_nl"
                                name="add_to_nl"
                                type="checkbox"
                                className={`${Styles.Form__checkbox__item}`}
                            />
                            <span
                                className={`small ${Styles.Form__checkbox__label}`}
                            >
                                S’abonner à la newsletter mensuelle de Spintank
                            </span>
                        </label>
                    </div>
                </div>

                <div className={`${Styles.Form__row}`}>
                    <span className={`very-small`}>* Champs requis</span>
                </div>

                {this.props.isBtn && (
                    <button
                        type="submit"
                        className={`form__btn ${Styles.Form__btn} teasing-1`}
                    >
                        OK
                    </button>
                )}
                {this.props.isLargeBtn && (
                    <button
                        type="submit"
                        className={`btn btn--primary ${Styles.Form__btn__large}`}
                    >
                        <p className={`btn__text`}>Continuer la lecture</p>
                        <div className={`btn btn--primary__right`}>
                            <Icon
                                name="big-arrow-right"
                                width={32}
                                height={32}
                                fill="#000"
                            />
                            <Icon
                                name="big-arrow-right"
                                width={32}
                                height={32}
                                fill="#000"
                            />
                        </div>
                    </button>
                )}

                <p
                    className={`${Styles.Form__message} ${
                        this.state.isError
                            ? Styles.Form__message__is_errored
                            : ""
                    } ${
                        this.state.textResponse
                            ? Styles.Form__message__is_animated
                            : ""
                    }`}
                >
                    {this.state.textResponse}
                </p>
            </form>
        )
    }
}

FormMailwall.propTypes = {
    isLargeInput: PropTypes.bool,
    isBtn: PropTypes.bool,
    isLargeBtn: PropTypes.bool,
}

export default FormMailwall
